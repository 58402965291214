import Vue from 'vue';
import Vuex from 'vuex';
import { nanoid } from 'nanoid';
import router from '@/router';
import jwt from 'jsonwebtoken';
import { PublicClientApplication } from '@azure/msal-browser';
import bcrypt from 'bcryptjs';
import io from "socket.io-client";

Vue.use(Vuex);

const config = CONFIG;
const apiDir = config.apiDir;
const portalApiDir = config.portalApiDir;


const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = config.dev ? config.devApiPort : window.location.port;
const portalBaseUrl = `${protocol}//${hostname}:${port}/`;

const msalConfig = config.msalConfig;
msalConfig.auth.redirectUri = config.serverAddress;// `http://localhost:${port}`;
msalConfig.auth.postLogoutRedirectUri = config.serverAddress;//`http://localhost:${port}`;
const loginRequest = {
    scopes: config.loginScopes
};

let msalInstance;
let key = nanoid();
let roles = [];

const sign = (data, api = false) => {
    const apiKey = store.state.apiKey;
    return jwt.sign(data, api ? apiKey : key);
};

const verify = (data, api = false) => {
    const apiKey = store.state.apiKey;
    if (apiKey) {
        return jwt.verify(data, api ? apiKey : key);
    }
    return false;
};

const setRoles = (r) => {
    try {
        let authRoles = verify(r, true);
        roles = authRoles.authList;
        if (store.state.userSiteSettings) {
            roles = [store.state.userSiteSettings.role];
        }
    } catch (err) {
        console.error('invalid auth');
    }
};

const hasRole = (r) => {
    return roles.includes(r);
};


const accessible = (rolesToCheck) => {
    // console.log('checking access', JSON.stringify(rolesToCheck));
    const hasAccess = (role) => {
        // console.log('hasaccess', role, hasRole(role), hasRole('admin'));
        return hasRole(role) || hasRole('admin');
    };
    if (!Array.isArray(rolesToCheck)) {
        return hasAccess(rolesToCheck);
    }

    let canAccess = true;
    rolesToCheck.forEach((role) => {
        if (!hasAccess(role)) {
            canAccess = false;
        }
    });
    // console.log('this:', this);
    // let {email, oid} = this.$auth.user;
    return canAccess;
    // return true;
};

let account = null;

const getoid = () => {
    if (account) {
        try {
            let act = verify(account);
            if (act) {
                return act.username;
            }
            // return null;
        } catch (err) {
            // return null;
        }
    }
    return null;
};

const initAxios = () => {
    const oid = getoid();
    const apiKey = store.state.apiKey;
    if (apiKey) {
        const encryptedKey = bcrypt.hashSync(apiKey, 10);
        if (oid) {
            Vue.axios.defaults.headers.common['DC-User'] = oid;
        }
        Vue.axios.defaults.headers.common['DC-Auth'] = encryptedKey;
    }
};

import mmlModule from './mmls.js';

let store = new Vuex.Store({
    state: {
        refresh: true,
        loading: true,
        loggingIn: false,
        errorMessage: null,
        accessToken: '',
        account: null,
        dcauth: null,
        auth: {},
        customerId: null,
        customerData: null,
        userSiteSettings: null,
        portalBaseUrl,
        dorsettUser: null,
        apiKey: null,
        loginError: null
    },
    getters: {
        config () {
            return config;
        },
        msalConfig () {
            return msalConfig;
        },

        // account (state) {
        //     try {
        //         return verify(state.account);
        //         // return token;
        //     } catch (err) {
        //         return null;
        //     }
        // },

        loading (state) {
            return state.loading;
        },

        loggingIn (state) {
            return state.loggingIn;
        },

        loggedIn (state) {
            try {
                return !!verify(state.account);
            } catch (err) {
                return false;
            }
        },

        accessible (state) {
            return (roles) => {
                let r = state.dcauth;
                // console.log('getter accessible roles', roles);
                return accessible(roles);
            };
        },
        errorMessage (state) {
            return state.errorMessage;
        },
        /**
         * Returns the API key.
         * 
         * @param {object} state 
         * @returns {string|null} encrypted API key
         */
        apiKey (state) {
            if (state.apiKey) {
                return bcrypt.hashSync(state.apiKey, 10);
            }
            return null;
        }
    },
    mutations: {
        setUser (state, token) {
            try {
                if (!accessingCentral) {
                    localStorage.setItem('token', token);
                }
                let user = verify(token, true);
                state.account = sign(user);
                state.fullUser = user;
            } catch (err) {
                console.error('error saving user', err);
            }
        },

        setDorsettUser (state, user) {
            state.dorsettUser = {
                name: user.name,
                email: user.username
            };
        },

        setCustomer (state, customerId) {
            state.customerId = customerId;
            this.dispatch('getCustomerData', customerId);
        },

        error (state, msg) {
            state.errorMessage = msg;
        },

        setLoading (state, loading) {
            state.loading = loading;
        },
        setLoggingIn (state, loggingIn) {
            // console.log('setting logging in', loggingIn);
            state.loggingIn = loggingIn;
        },
        setAccessToken (state, token) {
            // state.accessToken = token;
            state.accessToken = sign(token);
        },

        setAccount (state, {rawAccount, redirect}) {
            store.commit('setDorsettUser', rawAccount);
            account = sign(rawAccount);
            state.account = account;
            state.dcauth = nanoid();
            if (store.getters.loggedIn) {
                const path = redirect || '/';
                router.push({
                    path
                });
            }
        },

        setAuth (state, {authList}) {
            state.dcauth = authList;
        },

        /**
         * Sets the API key.
         * 
         * @param {object} state 
         * @param {string} apiKey 
         */
        setApiKey (state, apiKey) {
            state.apiKey = apiKey;
        },

        /**
         * Sets the error message the user will see in the DCCS login page.
         * 
         * @param {object} state 
         * @param {string} error 
         */
        setLoginError (state, error) {
            state.loginError = error;
        }
    },
    actions: {
        // authentication/login
        login ({state, dispatch}, redirectPath) {
            state.refresh = false;
            // msalInstance.loginRedirect(loginRequest);
            msalInstance.loginPopup(loginRequest).then(async (loginResponse) => {
                await dispatch('handleResponse', {
                    resp: loginResponse,
                    redirect: redirectPath
                });
            }).catch(function (error) {
                //login failure
                console.log(error);
            });
        },

        logout ({state, dispatch}) {
            const finish = () => {
                state.account = null;
                if (!accessingCentral) {
                    localStorage.setItem('token', null);
                }
                router.push('/login');
            };
            dispatch('disconnectSocket');
            if (msalInstance) {
                msalInstance.logoutRedirect({
                    onRedirectNavigate: (url) => {
                        // Return false if you would like to stop navigation after local logout
                        // console.log('logout redirecting to login');
                        finish();
                        return false;
                    }
                });
            } else {
                finish();
            }
        },

        async handleResponse ({state, commit, dispatch}, {resp, redirect}) {
            commit('setLoggingIn', false);
            if (resp) {
                const apiKey = await dispatch('validateLogin', resp.accessToken);
                if (apiKey) {
                    commit('setApiKey', apiKey);
                    commit('setAccount', {
                        rawAccount: resp.account,
                        redirect
                    });
                }
            } else {
                const currentAccounts = msalInstance.getAllAccounts();
                if (currentAccounts === null) {
                    return;
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    commit('setAccount', {rawAccount: currentAccounts[0]});
                    // username = currentAccounts[0].username;
                    // console.log(JSON.stringify(currentAccounts[0], null, 4));
                }
            }
        },

        async startup ({state, commit}, needsPerm = true) {
            initAxios();
            if (needsPerm) {
                let authList = await Vue.axios.get(`${apiDir}/getPerm`);
                authList = authList.data;
                setRoles(authList && authList.result);
                commit('setAuth', {authList});
            }
        },

        async sendCommand (context, data) {
            let params = {
                data
            };
            // console.log('params:', params, key);
            let token = sign(params, true);

            let result = await Vue.axios.post(`${apiDir}/attemptAction`, {token});
            if (result && result.data) {
                return result.data;
            }

            // console.log('sendCommand result', result);
            return null;
        },

        async post (context, data) {
            const result = await Vue.axios.post(`${portalApiDir}/${data.url}`, data.params);
            return result.data;
        },

        async getCustomerData (context, id=context.state.customerId) {
            if (!id) {
                return;
            }
            const result = await this.dispatch('post', { url: 'getCustomer', params: { id } });
            if (result.customer) {
                const customer = result.customer;
                context.state.customerData = customer;
                context.state.userSiteSettings = customer.customersUsers.find(user => user.email === context.state.fullUser.email).customersCustomerUsers;
            }
        },
        /**
         * Initializes the socket connection. Requires the API key to be set.
         */
        connectSocket ({getters}) {
            const apiKey = getters.apiKey;
            if (apiKey) {
                window.socket = io.connect(
                    `${window.location.hostname}:${CONFIG.socketPort}`,
                    {
                        secure: !!CONFIG.public,
                        extraHeaders: {
                            'DC-Auth': apiKey
                        }
                    }
                );
            }
        },
        /**
         * Ends the socket connection.
         */
        disconnectSocket () {
            if (window.socket && window.socket.connected) {
                window.socket.disconnect();
                delete window.socket;
            }
        },
        /**
         * Checks that the access token is valid. If the token is valid, the serve will return the API key.
         * 
         * @param {object} context Vuex context
         * @param {string} accessToken 
         * @returns {string|undefined}
         */
        async validateLogin (context, accessToken) {
            const result = await Vue.axios.post(`/dccsapi/auth/validateLogin`, {accessToken});
            if (result?.data?.error) {
                console.error(result.data.error);
                context.commit('setLoginError', 'There was an error logging you in. Please refresh the page try again. If the problem persists, please contact engineering@dorsettcontrols.com');
            } else {
                return result.data.apiKey;
            }
        }
    },
    modules: {
        mmls: {
            namespaced: true,
            ...mmlModule
        }
    }
});

const accessingCentral = window.location.host.match('central') || (window.location.host.match('localhost') && config.defaultDir === 'central');

if (!accessingCentral) {
    const token = localStorage.getItem('token');
    if (token) {
        store.commit('setUser', token);
    }
}

if (accessingCentral) {
    store.state.loggingIn = false;
    // console.log('firing redirect');
    msalInstance = new PublicClientApplication(store.getters.msalConfig);
    msalInstance.handleRedirectPromise().then((response) => {
        store.dispatch('handleResponse', {
            resp: response
        });
    }).catch(err => {
        console.error(err);
    });
} else {
    store.state.loggingIn = false;
}

export default store;
