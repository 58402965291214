import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import vuetify from './plugins/vuetify';

import common from './mixins/common';

import instance from '@/utils/axios';
import VueYouTubeEmbed from 'vue-youtube-embed';

const VueAxios = {
    install (Vue, options) {
        Vue.axios = instance;
    }
};
Vue.use(VueAxios);

Vue.use(VueYouTubeEmbed);

// Vue.use(VueAxios, instance);
Vue.mixin(common);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
